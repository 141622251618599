import { getMobileOperatingSystem } from "./os";

export function setDateFormat(originalDate:string){
    if (!originalDate) {
        return;
    }
    var dateComponents = originalDate.split('/');
    const day = dateComponents[0];
    const month = dateComponents[1];
    const year = dateComponents[2].slice(-2);
    const formattedDate = `${day}/${month}/${year}`;
    
    return formattedDate;
 }

 export function getDateObjFromString(dateString: string, dateDelimiter: string, hasTime: boolean) {
    const os = getMobileOperatingSystem();
    if (os !== 'iOS') {
        return new Date(dateString);
    }
    try {
        const dateTimeSplitted = dateString.split(' ');
        const dateMonth = dateTimeSplitted[0].split(dateDelimiter)[0];
        const dateDay = dateTimeSplitted[0].split(dateDelimiter)[1];
        const dateYear = dateTimeSplitted[0].split(dateDelimiter)[2];
        const dateHour = dateTimeSplitted[1].split(':')[0];
        const dateMinutes = dateTimeSplitted[1].split(':')[1];

        if (dateString.includes('GMT')) {
            // "2024/04/01 01:00:00 GMT+1" iOS supported Format
            const GMTStr = dateTimeSplitted[dateTimeSplitted.length - 1].includes('GMT') ? dateTimeSplitted[dateTimeSplitted.length - 1] : '';
            const d = new Date(`${dateYear.startsWith('20') ? dateYear : `20${dateYear}`}/${dateMonth}/${dateDay} ${dateHour}:${dateMinutes}:00 ${GMTStr}`);
            return d;
        }

        return new Date(
            parseInt(dateYear.startsWith('20') ? dateYear : `20${dateYear}`, 10),
            parseInt(dateMonth, 10) - 1,
            parseInt(dateDay, 10),
            parseInt(dateHour, 10),
            parseInt(dateMinutes, 10),
            0
        );
    } catch(error) {
    
    }
    return new Date(dateString);
 }